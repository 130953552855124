import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"absolute":"","top":"","right":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-tray-plus")])],1)]}}])},[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.handleClick(i)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h1',{staticClass:"headline pb-4 mx-lg-auto"},[_vm._v(_vm._s(_vm.subgroup.subgroup_name || 'New Subgroup'))]),_c('ReportMenu',{attrs:{"reportIds":_vm.reportIds}}),_c(VTabs,{attrs:{"background-color":"transparent"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTab,{attrs:{"exact-path":"","to":'/subgroups/' + _vm.subgroup.id}},[_vm._v("Details")]),(_vm.subgroup.id > 0)?_c(VTab,{attrs:{"exact-path":"","to":'/subgroups/' + _vm.subgroup.id + '/hosts'}},[_vm._v("Hosts")]):_vm._e(),(_vm.subgroup.id > 0)?_c(VTab,{attrs:{"exact-path":"","to":'/subgroups/' + _vm.subgroup.id + '/paperwork'}},[_vm._v("Paperwork")]):_vm._e(),(_vm.subgroup.id > 0)?_c(VTab,{attrs:{"exact-path":"","to":'/subgroups/' + _vm.subgroup.id + '/itinerary'}},[_vm._v("Itinerary")]):_vm._e(),(_vm.subgroup.id > 0)?_c(VTab,{attrs:{"exact-path":"","to":'/subgroups/' + _vm.subgroup.id + '/namelist'}},[_vm._v("Namelist")]):_vm._e(),(_vm.subgroup.id > 0)?_c(VTab,{attrs:{"exact-path":"","to":'/subgroups/' + _vm.subgroup.id + '/flights'}},[_vm._v("Flights")]):_vm._e()],1),_c(VCard,[_c(VCardText,[_c(VTabsItems,{attrs:{"transition":"false"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('router-view')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }